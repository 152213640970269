import getHost from '../util/host';

const { WS_BASE_URL } = getHost();

const amica = {
	routes: ['/referralquote', '/referralquote/', '/enterprise', '/enterprise/', '/emailquote', '/emailquote/', '/expressquote', '/expressquote/', '/onlinequote', '/onlinequote/', '/searchquote', '/searchquote/', '/employee', '/employee/'],
	roles: ['MRS', 'CSR', 'DTC', 'OWNER'],
	global: {
		appTitle: 'Amica | Acquire',
		favicon: '',
		homepage: '/',
		carrierName: 'Amica.',
		loader: `${WS_BASE_URL}/static/images/amica-sureify-loading.gif`,
		loaderWebm: `${WS_BASE_URL}/static/images/amica-sureify-loading.webm`,
		loaderWebp: `${WS_BASE_URL}/static/images/amica-sureify-loading.webp`,
		loaderMp4: `${WS_BASE_URL}/static/images/amica-sureify-loading.mp4`,
		dialogBoxImg: `${WS_BASE_URL}/static/images/svg/dialog-box.svg`,
		crossMarkImg: `${WS_BASE_URL}/static/images/svg/xmark.svg`,
	},
	branding: {
		lightLogo: `${WS_BASE_URL}/static/images/svg/amica-dark-logo.svg`,
		darkLogo: `${WS_BASE_URL}/static/images/svg/amica-dark-logo.svg`,
		headerBgImage: `${WS_BASE_URL}/static/images/amica_header_bg.png`,
		desktopImage: `${WS_BASE_URL}/static/images/Sidebar_Amica_Image.jpg`,
		desktopImageWebp: `${WS_BASE_URL}/static/images/Sidebar_Amica_Image.webp`,
		quoteBannerDesktopImageWebp: `${WS_BASE_URL}/static/images/QuoteBanner_Updated.png`,
		quoteBannerMobileImageWebp: `${WS_BASE_URL}/static/images/QuoteBanner_Updated.png`,
		quoteBannerDesktopImage: `${WS_BASE_URL}/static/images/QuoteBanner_Updated.png`,
		quoteBannerMobileImage: `${WS_BASE_URL}/static/images/QuoteBanner_Mobile.png`,
		altText: 'Amica Logo',
		link: 'https://www.amica.com/',
	},
	customHeader: {
		isPresent:true,
		banner:{
			padding:'10px',
			color:'#000',
			fontWeight:'400',
		},
	},
	footer: {	
		logo: `${WS_BASE_URL}/static/images/amica_logo.svg`,
		altText: 'Amica',
		isHTML: true,
		phoneText: '1-800-555-5555',
		phoneNumber: '1-800-Amica ®',
		disclaimerDesc: '2020 Amica Insurance Company',
		supportPhoneNumber: '1-800-255-7828',
		showFooterNotes: true,
		socialLinks: [
			{
				id: 'facebook',
				icon: 'fa-facebook-f',
				linkFor: 'Facebook',
				linkUrl: 'https://www.facebook.com/Amica',
			},
			{
				id: 'twitter',
				icon: 'fa-twitter',
				linkFor: 'Twitter',
				linkUrl: 'https://www.twitter.com/Amica',
			},
			{
				id: 'linkedin',
				icon: 'fa-linkedin',
				linkFor: 'LinkedIn',
				linkUrl: 'https://www.linkedin.com/company/Amica/',
			},
			{
				id: 'youtube',
				icon: 'fa-youtube',
				linkFor: 'YouTube',
				linkUrl: 'https://www.youtube.com/Amica',
			},
			{
				id: 'pinterest',
				icon: 'fa-pinterest',
				linkFor: 'Pinterest',
				linkUrl: 'https://www.pinterest.com/Amica/',
			},
			{
				id: 'instagram',
				icon: 'fa-instagram',
				linkFor: 'Instagram',
				linkUrl: 'https://www.instagram.com/Amica/',
			},
		],
		footerLinksTop: [
		],
		footerLinksBottom: [
		],
	},
	theme: {
		global: {
			fontFamily:'Avenir-Medium',
			colorScheme: 'green',
			background: '#ffffff',			
			leftSidebarColor: '#ffffff',
			headerBackground: '#fff',
			footerBackground: '#00A88F',
			footerTextColor: '#ffffff',
			footerBanner: {
				button: {
					border: '0px solid transparent',
					selectedBgColor: '#BF4500',
					bgColor: '#fff',
					padding: '2px 12px',
					fontWeight: 'bold',
					outline: 'none !important',
					// fontSize:'1vw',
					borderOnFocus: '3px solid #000',
					/** Footer Banner Hover */
					// hover:{
					// 	selectedBgColor:'#000',
					// 	bgColor:'#C65001',
					// 	border: '2px solid #C65001',
					// },
				},
			},
			footerNotes: {
				font: '12px Apercu Condensed Regular',
				color: '#2d2d2d',
			},
			errorMessageColor: '#C94436',
			showPageInfo: true,
			actualThreshold: 80,
			maxChallengeTries: 3,
			buttonDescriptionTheme: {
				color: '#2d2d2d',
				fontSize: '16px',
				fontWeight: 300,
				lineHeight: '24px',
				margin: '8px 0',
			},
			primaryButton: {
				shape: 'oval',
				color: 'blue',
			},
			text: {
				headingColor: '#2d2d2d',
				labelColor: '#2d2d2d',
				sectionHeaderColor: '#00534c',
			},
			alert: {
				fontSize: '15px',
			},
			reactSelect:{
				selectLabel: {
					color: '#2d2d2d',
					fontSize: '16px',
					lineHeight: '21px',
					fontWeight: 'bold',
					font:'15px Avenir-Medium',
					labelTooltipAlign: 'justify',
				},
				labelWrapperStyles:{
					alignItems:'baseline',
				},
				selectedBgColor:'#2D2D2D',
				border: '4px solid transparent',
				focusColor: '#757575',
				focusWidth: '2px',
				borderRadius: '4px',
				borderOnFocus: '4px solid #00806C',
				borderRadiusOnFocus: '4px',
				disabledPointer: 'default',
				placeholder: {
					color: '#333333',
					fontWeight: 'normal',
					fontSize: '15px',
					fontFamily: 'Avenir-Medium',
				},
				enableTabSelect: true,
				autoComplete: 'off',
			},
			breadcrumb: {
				activeTheme: {
					background: '#ffffff',
					color: '#333333',
					fontWeight: 'normal',
					border: '2px solid #0E7E75',
					borderRadius: '8px',
					boxShadow: '3px 3px 6px #d4d4d4',
					progressColor: '#00534c',
				},
				inActiveTheme: {
					background: '#FBFBFA',
					color: '#333333',
					fontWeight: 'normal',
					fontFamily: 'Avenir-Heavy',
					border: 'none',
					borderRadius: '8px',
					progressColor: '#00534c',
					margin: '0 auto',
				},
				progressTheme: {
					labelColor: '#333333',
					percentageColor: '#00534c',
					border: '2px solid #0E7E75',
					boxShadow: '3px 3px 6px #d4d4d4',
					progressDoneColor: '#00A88F',
					progressPendingColor: '#e4e2db',
					progressHeight: '8px',
					percentageFontWeight: 'normal',
					percentageFontFamily: 'Avenir-Heavy',
					margin: '0 auto 10px',
					progressLabelBottom: true, 
				},
				lineHeight: 1.2,
				wrapperTheme: {
					'max-width': '400px',
					'padding-right': '0px',
					margin: '45px auto 10px',
				},
			},
			checkboxTheme: {
				shape: 'standard_squircle',
				themeConfiguration: {
					bgColor: '#ffffff',
					checkedBg: '#00806C',
					fontColor: '#2d2d2d',
					labelMargin: '0.5em 0',
					font:'15px Avenir-Medium',
					border: '1px solid #0d756d',
					borderOnFocus: '3px solid #00806C',
					outlineOffset: '1px',
					borderRadiusOnFocus: '4px',
					disabledPointer: 'default',
					top: '50%',
					left: '10px',
				},
			},
			radioTheme: {
				fontSize: '15px',
				fontWeight: 'bold',
				checkedBg: '#00806C',
				checkedFg: '#ffffff',
				fillBg: '#aaaaaa',
				fontColor: '#333333',
				errorMessageColor: '#C94436',
				border: '1px solid #00806C',
				outline: true,
				outlineOnFocus: '4px solid #00806C',
				outlineOffset: '1px',
			},
			classicRadioTheme: {
				fontWeight: 'normal',
				height: 'auto',
				lineHeight: 1.5,
				margin: '10px',
				alignClassic: 'flex-start',
			},
			listItemCardTheme: {
				themeConfiguration:{
					selectedBgColor:'#BF4500',
					bgColor:'#fff',
					border:'4px solid transparent',
					padding:'15px 10px',
					disabledPadding:'15px 10px',
					disabledPointer: 'default',
					fontFamily: 'Avenir-Heavy',
					fontWeight: 'normal',
					borderOnFocus: '4px solid #00806C',
					disabledTheme:{
						selectedBgColor: '#D7D7D7',
						bgColor: '#3c3c3c',
					},
				},
				exactBtnTheme: true,
			},
			rightContentTheme: {
				'max-width': '980px',
				margin: '20px 40px 120px',
				marginWithFooterNotes: '20px 40px 10px',
			},
			leftContentTheme: {
				'max-width': '650px',
				'min-width': '280px',
			},
			reflexWrapperTheme: {
				hide: true,
			},
			commonButtonTheme: {
				disabledPointer: 'default',
			},
			modalButtonTheme: {
				themeConfiguration: {
					selectedBgColor:'#BF4500',
					bgColor:'#fff',
					fontFamily: 'Avenir-Heavy',
					fontWeight: 'normal',
					border:'4px solid transparent',
					borderOnFocus: '4px solid #00806C',
				},
			},
			toolTipmodal: {
				themeConfiguration: {
					zIndex: 9999999,
				},
			},
		},
		components: {
			input: {
				floatingLabel: false,
				labelPosition: 'top',
				labelIsHTML: true,
				width: 'L',
        		preTextLeftPadding: '0px',
				themeConfiguration: {
         			border: '1px solid #999999',
					font: '15px Avenir-Medium',
					labelText: {
						color:'#2d2d2d',
					},
				  	labelWrapperStyles: {
						alignItems:'baseline !important',
				  	},
					shouldFocusWithin: true,
					borderOnFocus: '#00806C',
					focusColor: '#757575',
					focusWidth: '2px',
					borderRadiusOnFocus: '3px',
					labelTooltipAlign: 'justify',
					tooltipPopperOffsetLeftPos: -150,					  
					disabledPointer: 'default',
					allowDisabledOpacity: true,
					placeHolder: {
						color: '#585858',
						fontSize: '16px',
						fontFamily: 'Avenir-Medium',
					},
				},
				textArea:{
					textAreaRowStep: 250,
					minRowCount: 2,
				},
				rangeSlider:{
					customWrapperMargin:'-64px 0px -32px 0',
					decrementButton:{
						label: '-',
						width: '40px',
						themeConfiguration: {
						  bgColor: '#00806C',
						  selectedBgColor: '#f7f6f4',
						  fontWeight: 'bold',
						  fontSize: '16px',
						  border: 'none',
						  padding:'8px',
						},
					},
					incrementButton:{
						label: '+',
						width: '40px',
						themeConfiguration: {
							bgColor: '#00806C',
							selectedBgColor: '#f7f6f4',
							fontWeight: 'bold',
							fontSize: '16px',
							border: 'none',
							padding:'8px',
						},
					},
					themeConfiguration:{
      				 	selected: '#00806C',
      					sliderFillColor:'#88c369',
					 },
					 customLabelStyles:[
						{ left:'96%',fontSize:'12px',lineHeight:'12px',color:'#333333' },
						{ left:'3%',fontSize:'12px',lineHeight:'12px',color:'#333333' },
					  ],
				},
				rangeStepper: {
					decrementBtnTheme: {
						selectedBgColor: '#f7f6f4',
						bgColor: '#979797',
						fontSize: '24px',
						fontWeight: 900,
						padding: '1px 16px',
						border: '1px solid #d4d4d4',
						borderRight: 'none',
						borderRadius: '5px 0 0 5px',
					  },
					  labelTheme: {
						padding: '1px 8px 1px 16px',
						border: '1px solid #d4d4d4',
						'min-width': '120px',
						'max-width': '340px',
						width: '100%',
						'font-size': '16px',
						display: 'flex',
						'align-items': 'center',
						'justify-content': 'flex-end',
					  },
					  incrementBtnTheme: {
						selectedBgColor: '#f7f6f4',
						bgColor: '#979797',
						fontSize: '24px',
						fontWeight: 900,
						padding: '1px 16px',
						border: '1px solid #d4d4d4',
						borderLeft: 'none',
						borderRadius: '0 5px 5px 0',
					  },
				},
			},
			selectOption: {
				floatingLabel: false,
				labelPosition: 'top',
				labelIsHTML: true,
				width: 'L', 
				disableCapitalization:true,
				disabledPointer: 'default',
				tooltipPopperOffsetLeftPos: -150,
				disabledTheme: {
					backgroundColor: '#fafafa',
					borderColor: '#999999',
					selectedColor: '#333333',
				},
			},
			tabs: {
				themeConfiguration:{
					tab: {
						bgColor: '#f7f7f7',
						color: '#767676',
					},
					grid: {
					color: 'inherit',
					bgColor: '#f7f7f7',
					selectedBgColor: '#00806C',
					},
				},
			},
			singleSelect: {
				shapeOverride: {},
				width: 'L',
				floatingLabel: false,
				labelIsHTML: true,
				labelPosition: 'top',
				shape: 'rectangle',
				overlap: false,
				labelMargin: '0 !important',
				labelFont: '16px',
				removeMargin: true,
				customPadding:'17px 20px',
				font:'15px Avenir-Medium',
				disabledPointer: 'default',
				tooltipPopperOffsetLeftPos: -150,
			},
			multiSelect: {
				width: 'L',
				floatingLabel: false,
				labelIsHTML: true,
				labelPosition: 'top',
				disabledPointer: 'default',
				tooltipPopperOffsetLeftPos: -150,
			},
			datepicker: {
				width: 'L',
				floatingLabel: false,
				labelIsHTML: true,
				labelPosition: 'top',
				showPopper: true,
				guide: true,
				isDateChange: true,
				trackCalendarPopper: true,
				showWCAGCloseBtn: false,
				disabledPointer: 'default',
				maskOverwrite: false,
				tooltipPopperOffsetLeftPos: -150,
			},
			modal: {
				top: '20%',
				showScrollbar: true,
			},
			
		},
		pageOverrides: {
			quote: {
				padding: '20px 0 0',
				accordionTheme: {
					selectedBgColor: '#FFF',
					bgColor: '#312D30',
					width: '100%',
					alignment: 'left',
					fontWeight: 'bold',
					padding: '0',
					border: 'none',
					borderOnFocus: '4px solid #00806C',
					outline: 'none !important',
					accordionTitleBackground: '#E4E2DB', 
				},
				fullWidthButton: {
					flexDirection: 'column',
					alignItems: 'flex-start',
					width: '256px',
					margin: '0 auto',
					maxWidth: '780px',
					themeConfiguration:{
						selectedBgColor:'#BF4500',
						bgColor:'#fff',
						padding:'10px 20px',
						disabledPadding:'10px 20px',
						fontWeight: '900',
						border:'1px solid transparent',
						borderOnFocus: '4px solid #00806C',
						disabledPointer: 'default',
						disabledTheme:{
							selectedBgColor: '#D7D7D7',
							bgColor: '#3c3c3c',
						},
						outline: 'none !important',
					},
					
				},
				icons: {
					'Quote.NeedsCalcLabel': {
						src: `${WS_BASE_URL}/static/images/svg/amica-calculator.svg`,
						theme: {
							'padding': '0 15px 5px 0',
							border: 'none',
						},
					},
				},
				leftContentTheme: {
					'max-width': '399px',
					margin: '0 20px',
				},
				tableTheme: {
					headerFontWeight: 400,
					headerFontFamily: 'Avenir-Heavy',
					dataFontWeight: 400,
					wrapperTheme: {
						width: '80%',
						margin: '0 auto',
					},
					hrTheme: {
						separatorWidth: '2px',
						separatorColor: '#e1e0d9',
						margin: '10px 0',
					},
					rowTheme: {
						justifyContent: 'space-around',
					},
				},
				sectionHeader:{
					heading: {
						fontFamily:'Avenir-Heavy',
					},
				},
				allowSectionScroll: true,
			},
			plan: {
				singleSelect:{
					shape:'card_button',
					removeMargin: true,
					cardButtonTheme: {
						width: '100%',
						selectedBgColor: '#BF4500',
						bgColor: '#fff',
						fontWeight: 900,
						fontSize: '18px !important',
						padding: '10px 5px',
						border: '1px solid #d4d4d4',
						borderRadius: '3px',
						borderOnFocus: '4px solid #d4d4d4',
						borderOnFocusMobile: '4px solid #00806C',
						outline: 'none !important',
					},
					themeConfiguration: {
						borderOnFocus: '4px solid #00806C',
						selectedBgColor: '#00806C',
					},
				},
				padding: '0 0 20px 0',
				card: {
					themeConfiguration: {
						maxWidth: '870px !important',
						width: '100%',
					},
				},
				sectionHeader:{
					margin:'20px auto',
					maxWidth:'870px',
				},
				selectOption: {
					floatingLabel: false,
					labelPosition: 'top',
					labelIsHTML: true,
					disableCapitalization:true,
					width: {
						tablet: '220px',
						desktop: '300px',
					},
				},
				globalHeaderTheme: {
					headerPosition: 'fixed',
					alignBanner: 'right',
				}
			},
			knockout: {
				padding: '0px',
				rightContentTheme: {
					margin: '0 auto',
				},
				card: {
					themeConfiguration: {
						maxWidth: '808px !important',
						width: '100%',
						margin: '18px auto !important',
					},
				},
				sectionHeader:{
					margin:'20px auto',
					maxWidth: '808px',
				},
				tableTheme: {
					wrapperTheme: {
						borderLeft: 'none',
						borderRight: 'none',
						maxWidth: '600px',
					},
					hrTheme: {
						showLastRow: true,
						separatorWidth: '1px',
					},
					columnStyles: [
						{
							'min-width': '60px',
						  	'max-width': '100px',
							'width': '100%',
							'padding-left': '15px',
						},
						{
							'padding-left': '10px',
						  	'border-left': '1px solid',
						},
					],
				},
				globalHeaderTheme: {
					alignBanner: 'right',
				},
				centerContent: true,
			},
			nonclean: {
				padding: '0px',
				rightContentTheme: {
					margin: '0 auto',
				},
				card: {
					themeConfiguration: {
						maxWidth: '808px !important',
						width: '100%',
						margin: '18px auto !important',
					},
				},
				sectionHeader:{
					margin:'20px auto',
					maxWidth: '808px',
				},
				tableTheme: {
					wrapperTheme: {
						borderLeft: 'none',
						borderRight: 'none',
						maxWidth: '600px',
					},
					hrTheme: {
						showLastRow: true,
						separatorWidth: '1px',
					},
					columnStyles: [
						{
							'min-width': '60px',
						  	'max-width': '100px',
							'width': '100%',
							'padding-left': '15px',
						},
						{
							'padding-left': '10px',
						  	'border-left': '1px solid',
						},
					],
				},
				globalHeaderTheme: {
					alignBanner: 'right',
				},
				centerContent: true,
			},
			q: {
				buttons: {
					next: {
						maxWidth: '240px',
						themeConfiguration:{
							selectedBgColor:'#BF4500',
							bgColor:'#fff',
							border:'4px solid transparent',
							fontWeight: 'normal',
							padding:'15px 10px',
							disabledPadding:'15px 10px',
							fontFamily: 'Avenir-Heavy',
							buttonBorder: '4px solid transparent',
							borderOnFocus: '4px solid #00806C',
						},
					},
					back: {
						shape: 'rectangle',
						alignItems: 'flex-end',
						themeConfiguration:{
							selectedBgColor:'#BF4500',
							bgColor:'#fff',
							border:'4px solid transparent',
							fontWeight: 'normal',
							padding:'15px 20px',
							disabledPadding:'10px 20px',
							fontFamily: 'Avenir-Heavy',
							buttonBorder: '4px solid transparent',
							borderOnFocus: '4px solid #00806C',
						},
					},
				},
				sectionHeader: {
					heading: {
						fontSize: '30px',
						fontWeight: 'normal',
						fontFamily: 'Avenir-Heavy',
						padding: '0 0 0 100px',
						underlineBorder: '2px solid #00534c',
					},
				},
				globalHeaderTheme: {
					headerPosition: 'fixed',
					alignBanner: 'right',
				},
			},
			questions: {
				padding: '20px 0 0',
				listButtonsTheme: {
					closeButtonTheme: {
						borderOnFocus: '4px solid #00806C',
						borderRadiusOnFocus: '4px',
					},
				},
				addListItemButton: {
					border: '2px dashed #d4d4d4',
					'border-radius': '3px',
					padding: '14px 18px',
					color: '#00806C',
					'font-family': 'Avenir-Heavy',
					'font-weight': 'normal',
					borderOnFocus: '4px solid #00806C',
				},
				listItemCardTheme: {
					body: {
						padding: '14px 0',
						background: '#f7f7f7',
						borderRadius: '0px',
					},
					text: {
						color: '#454545',
						padding: '0 20px',
						fontSize: '15px',
						cursor: 'default',
						'font-weight': '300',
					},
					customAccordion: true,
					editLinkTheme: {
						color: '#00806C',
						fontSize: '15px',
						fontWeight: 'normal',
						fontFamily: 'Avenir-Heavy',
						borderOnFocus: '4px solid #00806C',
					},
					deleteLinkTheme: {
						color: '#00806C',
						fontSize: '15px',
						fontWeight: 'normal',
						fontFamily: 'Avenir-Heavy',
						label: 'Remove',
						borderOnFocus: '4px solid #00806C',
					},
				},
				fieldSetTheme: {
					row: {
						justifyContent: 'flex-start',
					},
					label: {
						width: '50%',
						'font-size': '15px',
						'font-weight': '300',
					},
					value: {
						width: '50%',
						'font-size': '15px',
						'font-weight': '300',
						'margin-left': '30px',
					},
				},
				globalHeaderTheme: {
					headerPosition: 'fixed',
					alignBanner: 'right',
				},
				groupTheme: {
					fontSize: '26px',
					fontWeight: 'normal !important',
					fontFamily: 'Avenir-Heavy',
				},
				labelTheme: {
					fontSize: '15px !important',
					fontWeight: 'normal !important',
					fontFamily: 'Avenir-Medium',
				},
				rightContentTheme: {
					margin: '54px 40px 120px 20px',
					'max-width': '980px',
				},
				leftContentTheme: {
					background: '#F7F6F4',
					'max-width': '400px',
					margin: '0 20px',
				},
				pdfTheme: {
					skipScrollCheck: true,
				},
			},
			signature: {
				groupTheme: {
					fontSize: '26px',
					fontWeight: 'normal !important',
					fontFamily: 'Avenir-Heavy',
				},
				is_next_btn_hidden: false,
				is_prev_btn_hidden: true,
				globalHeaderTheme: {
					headerPosition: 'fixed',
					alignBanner: 'right',
				},
				rightContentTheme: {
					width: '90%',
				},
				padding: '5px 10px',
				card: {
					themeConfiguration: {
						maxWidth: '980px !important',
					},
				},
				pdfTheme: {
					hidePageLabel: true,
					skipScrollCheck: true,
					maxHeight: '350px',
					borderOnFocus: '4px solid #00806C',
				},
				labelTheme: {
					fontSize: '15px !important',
					fontWeight: 'normal !important',
					fontFamily: 'Avenir-Medium !important',
				},
			},
			'view-document': {
				globalHeaderTheme: {
					hideHeader: true,
				},
				globalFooterTheme: {
					hideFooter: true,
				},
			},
			thankyou: {
				centerContent: true,
			},
			completed: {
				padding: '0px',
				centerContent: true,
				globalHeaderTheme: {
					alignBanner: 'right',
				},
			},
			inactivesession: {
				padding: '40px 0',
			},
			challenge: {
				globalHeaderTheme: {
					headerPosition: 'fixed',
					alignBanner: 'right',
				},
			},
			timeout: {
				globalHeaderTheme: {
					headerPosition: 'fixed',
					alignBanner: 'right',
				},
			},
		},
	},
	layout: {
		quote: 'quote',
		loader: 'single',
		questions: 'double',
		review: 'single',
		quoteResults: 'single',
		quotereview: 'single',
		plan: 'plan',
		signature: 'signature',
		payment: 'single',
		timeout: 'single',
		challenge: 'challenge',
		completed:'completed',
		knockout: 'knockout',
		noquote: 'knockout',
		nonclean: 'nonclean',
		inactivesession: 'inactivesession',
	},
	features: {
		login: {
			enable: false,
			loginURL: '/auth',
			logoutURL: '/auth/logout',
			refreshURL: '/auth/refresh_token',
			loginMethod: 'jwt',
			OIDCAuthURL: '/v2/auth/auth/login',
		},
		identityCheck: {
			enable: true,
		},
		liveChat: {
			enable: true,
			scriptSrc: 'https://api.salemove.com/salemove_integration.js',
		},
		OIDCAuth: {
			enable: true,
		},
		ally: {
			hasModalBodyOutline: true,
		},
		customScrollPosition:'-160px',
		customScrollPositionCSR:'-50px',
	},
};

export default amica;
